import React, { useState,useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
function PromotionInput() {
  const [promotionList, setPromotionList] = useState([]);
  const getPromotion = async()=>{
    try{
      const res= await axios.get('https://bioraiz.in/bioraiz_admin/api/get_promotion');
      setPromotionList(res.data)
    }
    catch(err){console.log('error' + err)};
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getPromotion()
  }, []);
  return (
    <section>
      <div className="container my-5">
      <h3 className='pro_ducthead'>Our Promotional Gifts Range</h3>
        <div className="row my-4">
        {
                promotionList.map((item,index)=>{
                  console.log(item)
                  return(
                    <div className="col-md-4 col-sm-12" key={index}>
                  <LazyLoadImage className='product_img1' src={item.image} alt="image" />
              </div>

                  )
                })
              }
          {/* <div className="col-md-4 col-sm-12">
            <img className='product_img1' src={'./assets/images/Cgift1.jpg'} alt="" />
          </div> */}

          {/* <div className="col-md-4 col-sm-12">
            <img className='product_img1' src={'./assets/images/Cgift2.jpg'} alt="" />
          </div>

          <div className="col-md-4 col-sm-12">
            <img className='product_img1' src={'./assets/images/Cgift3.jpg'} alt="" />
          </div> */}

        </div>
      </div>
    </section>
  )
}

export default PromotionInput