import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, json, useParams } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { Modal } from "rsuite";
import Seo from "../components/Seo";

const SingleProduct = () => {
  const { id } = useParams();
  const [cat, setCat] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [modelImg, setModelImg] = useState();
  const [subCat, setSubCat] = useState([]);
  const [catClick, setCatClick] = useState("");
  console.log(catClick, "===============================");
  // const [foundCat, setfoundCat] = useState([]);
  //console.log("sub cat===========" + subCat);

  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  useEffect(() => {
    getProductData();
  }, [id, catClick]);

  const getData = async () => {
    try {
      const res = await axios.get(
        `https://bioraiz.in/bioraiz_admin/api/catebysub/${id}`
      );
      setCat(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductData = async () => {
    //console.log(subcategoryName, "Sub Category Nameee");
    setLoading(true);
    try {
      const res = await axios.get(
        //`https://bioraiz.in/bioraiz_admin/api/catebypro/${id}`
        `https://bioraiz.in/bioraiz_admin/api/subbypro/${id}`
      );
      if (catClick.length > 0) {
        const filteredData = res.data.filter(
          (item) => item.subcategory_name === catClick
        );
        setProductData(filteredData);
      } else {
        setProductData(res.data);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // console.log(productData, "ddddd");
  const handleOpen = (img) => {
    // console.log("text" + img);
    setOpen(true);
    setModelImg(img);
  };

  const handleClose = () => setOpen(false);

  const handlesubcat = async (subcategoryName) => {
    // console.log(subcategoryName, "Sub Category Name");
    // setSelectedSubcategory(subcategoryName);
    // setLoading(true);
    // try {
    //   const res = await axios.get(
    //     //`https://bioraiz.in/bioraiz_admin/api/catebypro/${id}`
    //     `https://bioraiz.in/bioraiz_admin/api/subbypro/Syrup`
    //   );
    //   console.log(res.data, "==============================================");
    //   setSubCat(res.data);
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    // }
  };

  return (
    <section>
    <Modal open={open} onClose={handleClose} size={"sm"}>
      <button
        className="text-danger"
        style={{ position: 'absolute', top: '15px', right: '22px' }}
        onClick={handleClose}
      >
        <i className="fas fa-times"></i>
      </button>
      <Modal.Body>
        <img
          src={modelImg}
          alt="Image"
          style={{ width: "100%", height: "400px" }}
        />
      </Modal.Body>
    </Modal>

    <div className="container-fluid mt-5 p-0">
      {/* <div className="bg_cont__45">
        <h2 style={{ color: "#fff" }}>Our Products</h2>
      </div> */}
    </div>

    <div className="container my-5 mt-5">
      <div className="row">
        <div className="col-md-3 col-12 catg-rj pro_cate_44">
          <h5 className="text-center text-white catg-rj1 p-1 my-3 text-uppercase bg-danger.bg-gradient">
            {id}
          </h5>
          <ul className="catg-rj">
            {cat.map((item) => (
              <li
                key={item.id}
                onClick={() => setCatClick(item.subcategory_name)}
              >
                {console.log(item)}
                <Link
                  to={`/products-single/${item.name}`}
                  className={
                    catClick === item.subcategory_name
                      ? "categ_list__4"
                      : "custom_color"
                  }
                >
                  {item.subcategory_name}
                </Link>
                <Seo
                  title={`Bioraiz Healthcare | ${id} `}
                  description="The Best Pharma Products in the World"
                  type="webapp"
                  name="Bioraiz Pharma"
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-9 col-12 text-center">
          <div className="mbl-category mb-4">
            <h5>Anti Depressants</h5>
          </div>
          {isLoading ? (
            <div>
              {/* Loading spinner */}
            </div>
          ) : productData.length === 0 ? (
            <h3 className="text-danger">No data found</h3>
          ) : (
            <>
              <table className="prod__tab_4 d-none d-md-table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Composition</th>
                    <th>Packing</th>
                    <th>Catalog</th>
                    <th>Product</th>
                  </tr>
                </thead>
                <tbody>
                  {productData.map((item) => (
                    <tr key={item.id}>
                      <td>{item?.name}</td>
                      <td>{item?.composition}</td>
                      <td>
                        {item.size} {item.package_type}
                      </td>
                      <td>
                        <button onClick={() => handleOpen(item.image)}>
                          Open Modal
                        </button>
                      </td>
                      <td>
                        <button onClick={() => handleOpen(item.image)}>
                          Open Modal
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {productData.map((item, index) => (
                <table className="prod__tab_4 hideondesktop" key={index}>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Composition</th>
                      <th>Packing</th>
                      <th>Catalog</th>
                      <th>Product</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={item.id}>
                      <td>{item?.name}</td>
                      <td>{item?.composition}</td>
                      <td>
                        {item.size} {item.package_type}
                      </td>
                      <td>
                        <button onClick={() => handleOpen(item.image)}>
                          Open Modal
                        </button>
                      </td>
                      <td>
                        <button onClick={() => handleOpen(item.image)}>
                          Open Modal
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  </section>
  );
};

export default SingleProduct;
