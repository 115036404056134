import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const PharmaFranchiseCompany = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>

        <div className="container-fluid mt-5 p-0">
          <div className="bg_cont__45-pharma_pharmafcomp ban__nerrr">
            <h2 style={{ color: '#fff', zIndex: '99' }}>Pharma Franchise Companies</h2>
          </div>
        </div>
        <div className="container mt-5">
          <div className="col-12 col-lg-12 mb-5">
            <h2>Bioraiz Healthcare: Your Trusted Pharma Franchise Partner!</h2>
            <p className="serv_all__para">Pharma franchise companies have gained immense popularity in recent years, and for good reason. These companies provide a fantastic opportunity for individuals who are looking to venture into the pharmaceutical industry. A pharma franchise company essentially allows individuals to become the authorized distributors of a particular pharmaceutical brand or company. This means that they have the rights to sell the products of that brand within a specific geographical area. This concept, known as PCD (Propaganda Cum Distribution) pharma franchise, has proven to be highly successful and profitable for both the franchisee and the parent company.</p>
            <p className="serv_all__para">The benefits of partnering with a pharma franchise company are numerous. Firstly, it offers individuals the chance to start their own business without the need for extensive investment or prior experience in the pharmaceutical field. The parent company provides all the necessary support and training to ensure that the franchisee is equipped with the knowledge and skills required to run a successful business. Additionally, the franchisee benefits from the established reputation and credibility of the parent company, which makes it easier to build trust with customers and generate sales.
            </p>
            <h2>We Are The Reliable Pharma Franchise Choice For You!
            </h2>
            <p className="serv_all__para">For pharmaceutical companies, partnering with pharma franchise companies is a strategic move that allows them to expand their reach and penetrate new markets. By choosing to work with franchisees, these companies can tap into local networks and gain access to a wider customer base. This not only increases their sales and revenue but also helps in brand building and market penetration. Moreover, pharma franchise companies often act as a cost-effective distribution channel for these companies, as they bear the expenses of setting up and managing retail outlets.
            </p>
            <p className="serv_all__para">Therefore, pharma franchise companies offer a win-win situation for both individuals looking for business opportunities and pharmaceutical companies looking to expand their reach. The concept of PCD pharma franchise has revolutionized the pharmaceutical industry by creating a mutually beneficial partnership that thrives on collaboration and shared success. With the right support and guidance from the parent company, individuals can achieve financial independence and build a thriving business in the pharmaceutical sector.</p>
            <h2>Perks Of Choosing Bioraiz Healthcare As Your Pharma Franchise Partner!

            </h2>
            <p className="serv_all__para">The Indian pharmaceutical industry has made a significant impact on global healthcare, and Bioraiz Healthcare is proud to be one of the top PCD Pharma Franchise Companies in India. Our commitment to providing accessible and life-saving medicines has earned us the trust of patients and healthcare professionals nationwide. We conduct our business with integrity and professionalism, and we are committed to making a positive impact on the communities we serve. Our values are centered around:
            </p>

          </div>

          <div className="col-12 col-lg-12 mt-4 mb-5">
            <ul>
              <li style={{ listStyleType: 'disc' }}><p><b>Innovation: </b>We invest in cutting-edge research and development to stay ahead of the curve and provide the best possible solutions for our customers.</p></li>
              <li style={{ listStyleType: 'disc' }}><p><b>Accessibility: </b>We believe that everyone deserves access to quality medicines, regardless of their location or financial situation.</p></li>
              <li style={{ listStyleType: 'disc' }}><p><b>Transparency: </b>We operate with transparency and honesty in all our business dealings.
              </p></li>
              <li style={{ listStyleType: 'disc' }}><p><b>Excellence: </b>We strive for excellence in everything we do, from the products we develop to the service we provide.
              </p></li>

            </ul>
            <h4><b>Our Mission</b></h4>
            <p>At Bioraiz Healthcare, we aim to become leading providers of pharma products, including PCD franchise, pharma franchise, and pharma PCD franchise possibilities. We believe that everyone deserves access to quality medicines, and we are working constantly to make this a reality. Our mission is to provide innovative and affordable healthcare solutions that meet the needs of society.
            </p>
            <h4><b>Our Products</b></h4>
            <p>Our product range is designed to meet the requirements of society, and we are constantly working to expand our offerings to address emerging healthcare challenges. By our products we aim to establish ourselves as a leading worldwide pharma company known for our steadfast dedication to expertise, quality, and innovation.
            </p>
            <h4><b>PCD Pharma Franchise Opportunities</b></h4>
            <p>We offer PCD Pharma Franchise opportunities to like-minded individuals and organizations who share our vision and values. Our collaboration enjoys a challenging and engaging work environment, with access to cutting-edge equipment and training. If you're looking for a rewarding business in the pharmaceutical industry, we invite you to explore our PCD Pharma Franchise opportunities.

            </p>
            <h4><b>Our Vision
            </b></h4>
            <p>WOur vision is to dominate the pharmaceutical industry in the coming years, and we are working towards this goal with dedication and passion. We believe that our commitment to innovation, accessibility, and excellence will enable us to achieve this vision and make a lasting impact on global healthcare.

            </p>
            <h4><b>Let’s Join Hands & Beat The Pharma Industry!

            </b></h4>
            <p>If you share our passion for improving healthcare and wellbeing, we invite you to join us on this journey. Whether you're a healthcare professional, a patient, or a business partner, we look forward to working with you to make a difference in the lives of people around the world.


            </p>
          </div>
        </div>
        {/* Footer ==== */}

        {/* Footer END ==== */}
      </div>
    </div>
  );
};

export default PharmaFranchiseCompany;
