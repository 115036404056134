import { useState } from "react";
import React from "react";
import { NavLink, Link } from "react-router-dom";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { IoMdClose } from "react-icons/io";
import Marquee from "react-fast-marquee";
const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [showOpen, setShowOpen] = useState(false);
  const [showOpen1, setShowOpen1] = useState(false);

  const handleDivClick = () => {
    setShowOpen(!showOpen);
  };
  const handleDivClick1 = () => {
    setShowOpen1(!showOpen1);
  };

  const handleSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleMenuToggle = () => {
    const menuDropdown = document.getElementById("menuDropdown");
    const bootstrapCollapse = new window.bootstrap.Collapse(menuDropdown);
    bootstrapCollapse.toggle();
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="topheadmarquee">
          <div className="row">
            <div className="col-lg-8 d-flex align-items-center px-5">
              <Marquee>
                <p className="top_announcement">
                  "Bioraiz Healthcare Pvt. Ltd. – Redefining health through
                  groundbreaking pharmaceuticals, personalized care, and a
                  passion for your well-being."
                </p>
              </Marquee>
            </div>
            <div className="col-lg-2 pe-4 py-2 col-sm-none d-flex align-items-center justify-content-start top_num_ber4">
              <div className="phn_777">
                <i className="fa-solid fa-phone" />
                +91 7289822446, 8950782077
              </div>
            </div>
            <div className="col-lg-2 pe-4 py-2 col-sm-none d-flex align-items-center justify-content-start top_maill_ber4">
              <div className="email_123">
                <i className="fa-solid fa-envelope-open-text" />
                bioraizhealthcare@gmail.com
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="header header-transparent rs-nav">
        <div className="sticky-header navbar-expand-lg">
          <div className="menu-bar clearfix">
            <div className="container-fluid clearfix">
              <div className="menu-logo logo-dark">
                <NavLink to="/">
                  <img src="/assets/images/Bioraiz_Logo_final1.png" alt="Logo" />
                </NavLink>
              </div>
              <button
                className="navbar-toggler collapsed menuicon justify-content-end"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#menuDropdown"
                aria-controls="menuDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="secondary-menu">
                <ul>
                  <li className="search-btn">
                    <button
                      id="quikSearchBtn"
                      type="button"
                      className="btn-link"
                      onClick={handleSearchToggle}
                    >
                      <i className="las la-search"></i>
                    </button>
                  </li>
                  <li className="num-bx">
                    <a href="tel:(+01)999888777">
                      <i className="fas fa-phone-alt"></i> 7289822446
                    </a>
                  </li>
                  <li className="btn-area">
                    <NavLink
                      to="/contact-us"
                      className="btn btn-primary shadow cnct_btn1"
                    >
                      CONTACT US{" "}
                      <i className="btn-icon-bx fas fa-chevron-right"></i>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="nav-search-bar">
                <form action="#">
                  <input
                    name="search"
                    value=""
                    type="text"
                    className="form-control"
                    placeholder="Type to search"
                  />
                  <span>
                    <i className="ti-search"></i>
                  </span>
                </form>
                <span id="searchRemove" onClick={handleSearchToggle}>
                  <i className="ti-close"></i>
                </span>
              </div>
              <div
                className="menu-links navbar-collapse collapse justify-content-end"
                id="menuDropdown"
              >
                <div className="menu-logo">
                  <NavLink to="/">
                    <img
                      src="./assets/images/Bioraiz_Logo_final.png"
                      alt="Logo"
                    />
                  </NavLink>
                </div>
                <ul className="nav navbar-nav">
                  <li className="" onClick={handleMenuToggle}>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li onClick={handleMenuToggle}>
                    <NavLink to="/about-us">
                      About<i className="fas fa-plus"></i>
                    </NavLink>
                  </li>
                  <li
                    className={showOpen ? "open" : ""}
                    onClick={handleDivClick}
                  >
                    <NavLink to="/">
                      Services<i className="fas fa-plus"></i>
                    </NavLink>
                    <ul className="sub-menu">
                      <li className="add-menu-left">
                        <ul>
                          <li onClick={handleMenuToggle}>
                            <NavLink to="/PharmaFranchise">
                              Pharma Franchise
                            </NavLink>
                          </li>
                          <li onClick={handleMenuToggle}>
                            <NavLink to="/PharmaFranchiseCompany">
                              Pharma Franchise Companies
                            </NavLink>
                          </li>
                          <li onClick={handleMenuToggle}>
                            <NavLink to="/IndianPcdPharamaFranchise">
                              Indian PCD Pharma Companies
                            </NavLink>
                          </li>
                          <li onClick={handleMenuToggle}>
                            <NavLink to="/PcdPharmaFranchise">
                              PCD Pharma Companies
                            </NavLink>
                          </li>
                          <li onClick={handleMenuToggle}>
                            <NavLink to="/PcdPharmaFranchiseCompany">
                              PCD Pharma Franchise Companies
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li onClick={handleMenuToggle}>
                    <NavLink to="/products">
                      Our Products<i className="fas fa-plus"></i>
                    </NavLink>
                  </li>
                  <li onClick={handleMenuToggle}>
                    <NavLink to="/blogs">
                      Blogs<i className="fas fa-plus"></i>
                    </NavLink>
                  </li>
                  <li
                    className={showOpen1 ? "open" : ""}
                    onClick={handleDivClick1}
                  >
                    <NavLink to="javascript:;">
                      Gallery<i className="fas fa-plus"></i>
                    </NavLink>
                    <ul className="sub-menu left">
                      <li onClick={handleMenuToggle}>
                        <NavLink to="/VisualAid">Visual-Aid</NavLink>
                      </li>
                      <li onClick={handleMenuToggle}>
                        <NavLink to="/Product-Listing">Product-Picture</NavLink>
                      </li>
                      <li onClick={handleMenuToggle}>
                        <NavLink to="/Promotion-Input">Promotional-Inputs</NavLink>
                      </li>
                      
                    </ul>
                  </li>
                </ul>
                <ul className="social-media">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.google.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-google"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://twitter.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
                <div className="menu-close" onClick={handleMenuToggle}>
                  <i className="ti-close">
                    <IoMdClose size={25} />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
