import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Seo from '../components/Seo';

const PcdPharmaFranchise = () => {
  const handleSearchToggle = () => {
    // Implement your search toggle logic here
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Seo
        title="Bioraiz Healthcare | PcdPharmaFranchise"
        description="The Best Pharma Products in the World"
        type="webapp"
        name="Bioraiz Pharma"
      />
      <div>
        <div className="container-fluid mt-5 p-0">
          <div className="bg_cont__45__pcdpharma ban__nerrr">
            <h2 style={{ color: '#fff', zIndex: '99' }}>PCD Pharma Companies</h2>
          </div>
        </div>
        <div className="container mt-5">
          <div className="col-12 col-lg-12 mb-5">
            <h2>Bioraiz Healthcare: A PCD Pharma Company Like No Other!</h2>
            <p className="serv_all__para">If you are in search of a reputable and reliable PCD pharma company in India, then your search ends here at Bioraiz Healthcare. We are a leading PCD Pharma company that is committed to promoting quality healthcare by supplying, distributing, and marketing a diverse range of products in both domestic and international markets. With our well-structured PCD pharma program, we provide franchise opportunities to our clients, enabling them to establish their own successful businesses in the pharmaceutical industry.
            </p>
            {/* <img className="image-fluid mb-5" src="assets/images/Vis_ad_1.webp" /> */}
            <h2>Offering The Best Healthcare, Wellbeing & Quality Medications!</h2>
            <p className="serv_all__para">What sets us apart from other PCD Pharma companies is our commitment to excellence and customer satisfaction. We believe in building long-term relationships with our clients and providing them with the best support and guidance throughout their journey with us. Our PCD Pharma program is designed to empower entrepreneurs and help them establish their own profitable businesses. We provide all the necessary marketing materials, product training, and ongoing support to ensure the success of our franchise partners.
            </p>
            <p className='serv_all__para'> We have established partnerships with distributors and healthcare professionals in various regions across PAN India, allowing us to reach a wider audience and promote quality healthcare on a global scale. Our products are manufactured in state-of-the-art facilities that comply with standardized quality protocols, ensuring safety and efficacy.
            </p>
            <p className='serv_all__para'> When it comes to choosing a PCD Pharma company, trust and reliability are of utmost importance. With Bioraiz Healthcare, you can be assured of both. We have a proven track record of delivering high-quality products and services to our clients. Our commitment to ethical business practices and transparency sets us apart from other companies in the industry.

            </p>
            <p className='serv_all__para'>With its affiliated organizations, the PCD pharma franchise provides a win-win arrangement that includes marketing assistance and chances for the companies to exhibit their pharmaceutical products at approved locations. We work quickly to complete our responsibilities by applying innovative ideas, strategies, and premium drugs.
            </p>
          </div>

          <div className='mb-5'>
            <h2>Call Us Now for the Best PCD Pharma Companies!
            </h2>
            <p className='serv_all__para'>An array of experts can handle your various wants if you are a pharma expert seeking to join the Best PCD Pharma organization. Being a leader among the top pharmaceutical firms, we are able to give pharmaceuticals with delivery confirmation thanks to our exclusive technique. We are strong competitors in the pharmaceutical industry due to our size.
            </p>
            <p className='serv_all__para'>
              So, if you are looking for the best PCD Pharma franchise company in India, look no further than Bioraiz Healthcare. Join us in our mission to promote quality healthcare and make a difference in the lives of people around the world. Contact us today to learn more about our PCD Pharma program and start your journey towards success.

            </p>
          </div>

        </div>
        {/* Footer ==== */}

        {/* Footer END ==== */}
        <button className="back-to-top fa fa-chevron-up" style={{ display: 'none' }} />
      </div>
    </div>
  );
};

export default PcdPharmaFranchise;
